import React, { useContext, useEffect } from "react"

import { OutboundLink } from "gatsby-plugin-gtag"
import { FaGithub, FaTwitter, FaYoutube } from "react-icons/fa"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import EthPatron from "./ethPatron"
import styles from "./footer.module.scss"

const Footer = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
    const jordanmmckAddress = "0x29FF795B015652EdFB9883bB83B3E9bE3c207e62"
    const etherscanApiKey = "4NTDP6H156PJZNAVWG4M1VM16FXDYXH77I"
    const startblock = 0

    if (!state.data) {
      fetch(
        `https://api.etherscan.io/api?module=account&action=txlist&address=${jordanmmckAddress}&startblock=${startblock}&endblock=99999999&sort=desc&apikey=${etherscanApiKey}`
      )
        .then((res) => {
          if (res.ok) {
            return res.json()
          }
        })
        .then((data) => {
          dispatch({
            type: "SET_DATA",
            data: data.result.filter(
              (tx) => tx.from.toUpperCase() !== jordanmmckAddress.toUpperCase()
            ),
          })
        })
        .catch()
    }
  })

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.socials}>
        <OutboundLink
          href="https://twitter.com/jordanmmck"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter size={18} />
        </OutboundLink>
        <OutboundLink
          href="https://www.youtube.com/@jordanmmck"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaYoutube size={18} />
        </OutboundLink>
        <OutboundLink
          href="https://github.com/jordanmmck"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGithub size={18} />
        </OutboundLink>
      </div>
      <div className={styles.ethPatronsBox}>
        <div className={styles.ethPatronsTitle}>
          <span title="send me eth to get on the leaderboard">
            <OutboundLink
              href="https://etherscan.io/address/jordanmmck.eth"
              target="_blank"
              rel="noopener noreferrer"
            >
              ⟠ -> jordanmmck.eth
            </OutboundLink>
          </span>
        </div>
        {state.data ? (
          <div className={styles.ethPatrons}>
            <EthPatron txData={state.data[0]} />
            <EthPatron txData={state.data[1]} />
            <EthPatron txData={state.data[2]} />
          </div>
        ) : (
          <div className={styles.ethPatrons}>
            <EthPatron txData={null} index={0} />
            <EthPatron txData={null} index={1} />
            <EthPatron txData={null} index={2} />
          </div>
        )}
      </div>
    </footer>
  )
}

export default Footer
