import React from "react"
import { OutboundLink } from "gatsby-plugin-gtag"
import BlockiesIdenticon from "./blockiesIdenticon"
import styles from "./ethPatron.module.scss"

const EthPatron = ({ txData, index }) => {
  if (txData) {
    return (
      <div className={styles.patronStack} key={txData.hash}>
        <OutboundLink
          href={`https://etherscan.io/address/${txData.from}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <BlockiesIdenticon seed={txData.from} />
        </OutboundLink>
        <div className={styles.patronAmount}>
          {(txData.value / 1000000000000000000).toFixed(2)}
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.patronStack} key={index}>
        <div className={styles.placeholder}></div>
        <div className={styles.patronAmount}>–</div>
      </div>
    )
  }
}

export default EthPatron
