import React, { Component } from "react"
import blockies from "blockies-identicon"

export default class BlockiesIdenticon extends Component {
  state = {
    seed: this.props.seed || "",
    color: this.props.color || "#ff73dc",
    bgcolor: this.props.bgcolor || "#454bff",
    size: this.props.size || 14,
    scale: this.props.scale || 3,
    spotcolor: this.props.spotcolor || "#000",
  }

  componentDidMount() {
    this.draw()
  }

  draw() {
    blockies.render(this.state, this.canvas)
  }

  render() {
    return React.createElement("canvas", {
      ref: (canvas) => (this.canvas = canvas),
    })
  }
}
