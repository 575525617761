import React from "react"

import { Link } from "gatsby"

import styles from "./header.module.scss"
import DarkToggle from "./toggle"

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.titleRow}>
        <h1 className={styles.title}>
          <Link to="/">jordanmmck</Link>
        </h1>
        <div className={styles.toggle}>
          <DarkToggle />
        </div>
      </div>
      <nav>
        <ul className={styles.navList}>
          <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              to="/about"
            >
              About
            </Link>
          </li>
          <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              to="/videos"
            >
              Videos
            </Link>
          </li>
          <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              to="/artwork"
            >
              Artwork
            </Link>
          </li>
          <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              to="/writing"
            >
              Writing
            </Link>
          </li>
          <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              to="/collections"
            >
              Collections
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
