import React from "react"
import Toggle from "react-toggle"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import "./toggle.scss"

const DarkToggle = () => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => (
      <label htmlFor="toggle">
        <Toggle
          checked={theme === "dark"}
          icons={false}
          onChange={(e) => {
            toggleTheme(e.target.checked ? "dark" : "light")
          }}
        />
      </label>
    )}
  </ThemeToggler>
)

export default DarkToggle
