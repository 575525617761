import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import styles from "./layout.module.scss"
import "../styles/global.scss"

const Layout = (props) => (
  <div className={styles.body}>
    <div className={styles.container}>
      <div className={styles.content}>
        <Header />
        {props.children}
      </div>
      <Footer />
    </div>
  </div>
)

export default Layout
